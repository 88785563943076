.top-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    padding: 10px 20px;
  }
  
  .logo img {
    height: 50px;
    width: auto;
  }
  
  .top-nav {
    display: flex;
    list-style-type: none;
    margin-left: auto;
  }
  
  .top-nav a {
    color: #fff;
    text-decoration: none;
    margin-right: 20px;
    padding: 10px;
    border-bottom: 3px solid transparent;
  }
  
  .top-nav a.active {
    border-bottom-color: #fff; 
    background-color: #fff;
    color: #000;
  }
  
  .account-icon img {
    height: 50px;
    width: auto;
  }
  
  @media (max-width: 768px) {
    .top-nav a {
      margin-right: 10px;
      padding: 5px;
    }
  }

  .dropdown-menu {
    visibility: hidden;
  }

  .dropdown-menu.show {
    visibility: visible;
    z-index: 2;
    padding: 0;
    background-color: #000;
    font-weight: normal;
    color: white;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    border: 0;
    display: flex;
    flex-direction: column;
  }

