.year-selector-container {
  display: flex;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 10px;     
}
.year-selector {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 18px;
    margin-bottom: 10px;     
  }
  
.year-option {
    padding: 10px 10px;
    color: white;
    cursor: pointer;
    background-color: black; 
    border: 1px solid white; 

  }
  
.year-option.selected {
    background-color: grey; 
  }