/* * {
    border: 1px solid white;
    
} */

#index-container {
    width: 100%;
    height: calc(100% - 74px);
    position: absolute;
    /* margin: 0 auto; */  
}






.label {
    width: 100px;
    text-align: center;
}

#analyze-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #ed0606;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


.chart-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    padding: 10px 20px;
}

.chart-container {
    width: 30%;
    flex-grow: 1;
    border: 1px solid white; 

    /* align-content: center; */
}

