.temperature-selector-container {
  display: flex;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 10px;     
}
.temperature-selector {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 18px;
    margin-bottom: 10px;     
  }
  
.temperature-option {
    padding: 10px 10px;
    color: white;
    cursor: pointer;
    background-color: black; 
    border: 1px solid white; 

  }
  
.temperature-option.selected {
    background-color: grey; 
  }