.histo-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 0px; /* Space between grid items */
  }
  
  .histo-grid-item {
    height: 33%;
    width: 50%;
  }
  