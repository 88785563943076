.map-legend {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
}

.ssp-title {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 30px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10%;
}

.legend-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid white;
}

.legend-text {
  margin-left: 5px;
}

.legend-dropdown {
  width: 200px;
}