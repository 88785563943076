.mapdropdown {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .mapdropdown-select {
    padding: 10px;
    background-color: #000; 
    font-weight: bold;
    color: white;
    border-radius: 4px;
    font-size: 16px;
    width: 260px;
    text-align: center;
    border: 1px solid white;
  }
  
  .mapdropdown-select option {
    background-color: #000; 
    color: white;
  }
