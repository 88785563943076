.graph-panel-container {
    /* display: flex;
    flex-direction: column;
    flex: 1; */
    height: 100%;
    overflow: auto;
    position: absolute;
    left: 0 px;
    width: 60%;
    z-index: 9;
    color: #f0f0f0;
    background-color: #000;
    padding-right: 20px;
}

.graph-panel-header {
    display: grid;
    grid-template-columns: 90% 1fr; 
    color: #ddd;
}

.graph-company-name {
  position: absolute;
  top: 30px;
  right: 15px;
  color: #808080;
  color:#ddd;
}


.graph-panel-close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    background-color: #000;
}

.grid-container {
    display: grid;
    grid-template-columns: 30% 1fr; 
    gap: 10px;
    height: 90%;
    /* padding: 20px; */
  }
  
  .grid-item {
    padding: 3px;
    /* border: 1px solid #ddd; */
    text-align: justify;
    height: 300px;
  }
  .grid-double-item {
    padding: 3px;
    /* border: 1px solid #ddd; */
    text-align: justify;
    height: 600px;
  }

  .grid-text {
    /* display: flex; */
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 6px;
    font-size: 1.25em;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    overflow-y: auto;
  }
  
  .axis-label {
    text-align: right;
    font-size: 1.21em;
    color: gray;
    display: flex;
    justify-content: right;
    align-self: flex-end;
  }

  .row-title {
    /* grid-column: 1 / -1; */
    /* background-color: #e0e0e0;  */
    padding: 2px;
    /* text-align: center; */
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 15px;
    margin-top: 5px;
  }